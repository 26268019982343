import React from 'react';
import CloseIcon from '../images/icons/close-icon.svg';

export default function ModalHeader() {
    return <div className="fs-modal__heading mt-3">
        <div className="navbar-x close-modal">
            <div className="icon-outline icon-circle icon-outline--md">
                <CloseIcon onClick={() => window.history.back()} />
            </div>
        </div>
    </div>;
}