import React from 'react';
import removeMd from 'remove-markdown';
import ReactMarkdown from 'react-markdown/with-html';
import { buildImageObj, imageUrlFor } from './sanity/image-url';

function CompanyFull(props) {
  return (
    <article id="programs-article">
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
            <div className="row">
              {props.logo && props.logo.asset && (
                <div className="col-12 mt-3 mb-4 text-center">
                  <a href={props.companyUrl} className="company-logo" target="_blank" rel="noopener noreferrer">
                    <img
                      src={imageUrlFor(buildImageObj(props.logo))}
                    />
                  </a>
                </div>
              )}
              <div className="col-12 mt-3">
                {props.hideTitle ? (
                  <h1 className="sr-only">
                    {props.title && removeMd(props.title)}
                  </h1>
                ) : (
                    <h1 className="title text-center">
                      {props.title && removeMd(props.title)}
                    </h1>
                  )}
                {!props.programs ? (
                  <div></div>
                ) : (
                  <p className="text-center">
                    <strong>Programs:</strong> {props.programs && removeMd(props.programs)}
                  </p>
                )}
              </div>
            </div>
            <hr className="mb-5 mt-4" />
            <div>{props.body && <ReactMarkdown source={props.body} />}</div>
          </div>
        </div>
      </div>
    </article>

  );
}

export default CompanyFull;
