import React from 'react';
import { graphql } from 'gatsby';
import _ from 'lodash';
import CompanyFull from '../components/company-full';
import ModalHeader from '../components/modal-header';
import SEO from '../components/seo';
import Wrapper from '../containers/wrapper';
import Error from '../components/error';

export const query = graphql`
    query CompanyTemplateQuery($id: String!) {
        company: sanityCompany(id: { eq: $id }) {
            id
            title
            programs
            slug {
                current
            }
            publishedAt
            companyUrl
            logo {
                asset {
                    _id
                }
            }
            body
            categories {
                _id
                categoryTitle
            }
            hideTitle
        }
    }
`;

const CompanyTemplate = props => {
    const { data, errors } = props;
    const company = _.get(data, 'company', null);

    if (!company) {
        return <Error message={'GraphqQL query returned no company'} />;
    }

    return (
        <Wrapper>
            <SEO title={company.title} />
            <ModalHeader />
            <CompanyFull {...company} />
        </Wrapper>
    );
};

export default CompanyTemplate;
