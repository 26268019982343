import React from 'react';
import Header from '../components/header/header';

const Error = (props) => (
  <>
    <Header />
    <div className="layout">
        <div className="container">
            <h1>Error</h1>
            {props.message && <p>{props.message}</p>}
        </div>
    </div>
  </>
);

export default Error;
